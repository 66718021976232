import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { ROUTES } from '../../common/routes';
import { getIsLoggedIn } from '../../selectors/session';

const LOGGED_IN_PATHS_WITHOUT_FOOTER = [
  '^(/dashboard)',
  '^(/manage-candidates)',
  `^/${ROUTES.recommendedTalents}/?$`,
  `^(/${ROUTES.companyLocationUpdateRoute})`,
  `^(/${ROUTES.upgradePlanRoute})`,
  `^(/${ROUTES.companyReportStatus})`,
  '^(/job/create)',
  '^(/settings/).*',
  '^(/job/edit/).*',
  '^(/job/draft/).*',
  '^(/job/copy/).*',
  '^(/company/edit)',
  '^(/company/team)',
  '^(/talents/).*',
  '^(/onboarding)',
  '^(/findTalents)',
  '^(/jobs/).*(/applications).*',
  '^(/messages)',
  '^(/job/enhance/).*',
  '^(/talent-search).*',
  `^(/${ROUTES.features}).*`,
  `^(/${ROUTES.jobMetrics}).*`,
];

const LOGGED_OUT_PATHS_WITHOUT_FOOTER = [
  '^(/login)',
  '^(/signup)',
  '^(/forgot-password)',
  '^(/email-verification)',
];

const useShowFooter = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const [showFooter, setShowFooter] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (
      (isLoggedIn &&
        LOGGED_IN_PATHS_WITHOUT_FOOTER.some(route =>
          new RegExp(route, 'i').test(location.pathname)
        )) ||
      LOGGED_OUT_PATHS_WITHOUT_FOOTER.some(route =>
        new RegExp(route, 'i').test(location.pathname)
      )
    ) {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
  }, [isLoggedIn, location.pathname]);

  return showFooter;
};

export default useShowFooter;

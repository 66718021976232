import { TypePolicies } from '@apollo/client';

import { JobMetricsSummary } from './types';

export const jobMetricsCacheTypePolicies: TypePolicies = {
  GetJobsMetricsOutput: {
    keyFields: false,
    fields: {
      noMetricsData: {
        read(_, { readField }) {
          const metricsSummary = readField(
            'summary'
          ) as JobMetricsSummary | null;
          if (!metricsSummary) return null;
          const {
            applicationsMetrics: { dailyBreakdown },
          } = metricsSummary;

          // BE guarantees that if there is no data, the dailyBreakdown will be an empty array
          if (!dailyBreakdown.length) {
            return true;
          }
          return null;
        },
      },
    },
  },
};
